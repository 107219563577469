.mpc-2-background-daytime-mode-202302-icon, .mpc-2-icon {
  width: 100vw;
  height: 134.688vw;
  object-fit: cover;
  position: absolute;
  top: 105vw;
  left: 0;
}

.mpc-2-icon {
  height: 116.771vw;
  top: 0;
}

.mpc-2-secure-multi-party-computation {
  width: 28.3333vw;
  height: 25vw;
  align-items: center;
  display: flex;
  position: absolute;
  top: 24.2708vw;
  left: 60.2083vw;
}

.mpc-2-frame-child {
  border-radius: var(--mpc-2-br-5xs);
  background-color: var(--mpc-2-dark-tanglepay);
  width: .41667vw;
  height: 8.33333vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-2-frame-item, .mpc-2-rectangle-parent {
  border-radius: var(--mpc-2-br-9xs);
  width: .625vw;
}

.mpc-2-frame-item {
  background-color: var(--mpc-2-light-t5);
  height: 8.33333vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-2-rectangle-parent {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25vw;
  display: flex;
  position: absolute;
  top: 14.7917vw;
  left: 3.75vw;
}

.mpc-2-what-is-mpc {
  font-size: var(--mpc-2-font-size);
  color: var(--mpc-2-dark-b1);
  width: 26.25vw;
  height: 2.70833vw;
  align-items: center;
  line-height: 2.91667vw;
  display: flex;
  position: absolute;
  top: 17.3958vw;
  left: 60.2083vw;
}

.mpc-2-icon1 {
  width: 42.6333vw;
  height: 32.2917vw;
  object-fit: cover;
  position: absolute;
  top: 17.1875vw;
  left: 11.875vw;
}

.mpc-2-div {
  width: 100%;
  height: 66.6667vw;
  text-align: left;
  font-size: var(--mpc-2-font1-size);
  color: var(--mpc-2-light-t1-day);
  font-family: var(--mpc-2-font);
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=mpc.85d949e4.css.map */
