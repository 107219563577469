.mpc-2-background-daytime-mode-202302-icon,
.mpc-2-icon {
  position: absolute;
  top: 2016px;
  left: 0;
  width: 1920px;
  height: 2586px;
  object-fit: cover;
}
.mpc-2-icon {
  top: 0;
  height: 2242px;
}
.mpc-2-secure-multi-party-computation {
  position: absolute;
  top: 466px;
  left: 1156px;
  display: flex;
  align-items: center;
  width: 544px;
  height: 480px;
}
.mpc-2-frame-child {
  position: relative;
  border-radius: var(--mpc-2-br-5xs);
  background-color: var(--mpc-2-dark-tanglepay);
  width: 8px;
  height: 160px;
  flex-shrink: 0;
}
.mpc-2-frame-item,
.mpc-2-rectangle-parent {
  border-radius: var(--mpc-2-br-9xs);
  width: 12px;
}
.mpc-2-frame-item {
  position: relative;
  background-color: var(--mpc-2-light-t5);
  height: 160px;
  flex-shrink: 0;
}
.mpc-2-rectangle-parent {
  position: absolute;
  top: 284px;
  left: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}
.mpc-2-what-is-mpc {
  position: absolute;
  top: 334px;
  left: 1156px;
  font-size: var(--mpc-2-font-size);
  line-height: 56px;
  display: flex;
  color: var(--mpc-2-dark-b1);
  align-items: center;
  width: 504px;
  height: 52px;
}
.mpc-2-icon1 {
  position: absolute;
  top: 330px;
  left: 228px;
  width: 818.56px;
  height: 620px;
  object-fit: cover;
}
.mpc-2-div {
  position: relative;
  width: 100%;
  height: 1280px;
  overflow: hidden;
  text-align: left;
  font-size: var(--mpc-2-font1-size);
  color: var(--mpc-2-light-t1-day);
  font-family: var(--mpc-2-font);
}
